@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@2@0;300;490;500;600;700;800;900&display=swap');

.contact {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: url('/src/assets/banner/contact_bg.jpg');
	background-size: cover;
	padding: 20px;
}

.contact .content {
	max-width: 1100px !important;
	text-align: center;
}

.contact .content h2 {
	font-size: 36px;
	font-weight: 500;
	color: #fff;
}

.contact .content p {
	font-weight: 300;
	color: #fff;
}

.container_class {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}

.container_class .contactInfo {
	width: 45%;
	display: flex;
	flex-direction: column;
}

.container_class .contactInfo .box {
	position: relative;
	padding: 20px 0;
	display: flex;
	width: 75%;
}

.container_class .contactInfo .box .icon {
	min-width: 60px;
	height: 60px;
	background: #003560;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: #fff;
	font-size: 22px;
}

.container_class .contactInfo .box .text {
	display: flex;
	margin-left: 20px;
	font-size: 16px;
	color: #ffff;
	flex-direction: column;
	font-weight: 300;
}

.container_class .contactInfo .box .text h3 {
	font-weight: 500;
	color: #003560;
}

.container_class .contactInfo .box .text p {
	font-weight: 900;
	color: black;
}

.contactForm {
	width: 40%;
	padding: 40px;
	background: #fff;
	opacity: 0.8;
	box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.6);
}

.contactForm h2 {
	font-size: 30px;
	color: #003560;
	font-weight: 500;
}

.contactForm .inputBox {
	position: relative;
	width: 100%;
	margin-top: 10px;
}

.contactForm .inputBox input,
.contactForm .inputBox textarea {
	width: 100%;
	padding: 5px 0;
	font-size: 16px;
	margin: 10px 0;
	border: none;
	border-bottom: 2px solid #333;
	outline: none;
	resize: none;
}

.contactForm .inputBox span {
	position: absolute;
	left: 0;
	padding: 5px 0;
	font-size: 16px;
	margin: 10px 0;
	pointer-events: none;
	transition: 0.5s;
	color: #003560;
}

.contactForm .inputBox input:focus ~ span,
.contactForm .inputBox input:valid ~ span,
.contactForm .inputBox textarea:focus ~ span,
.contactForm .inputBox textarea:valid ~ span {
	color: #003560;
	font-size: 12px;
	transform: translateY(-20px);
}

.contactForm .inputBox input[type='submit'] {
	width: 100%;
	background: #003560;
	color: #fff;
	border: none;
	cursor: none;
	padding: 10px;
	font-size: 18px;
	cursor: pointer;
}

@media (max-width: 700px) {
	.contact {
		padding: 30px;
		height: auto;
	}

	.container_class {
		flex-direction: column;
	}

	.container_class .contactInfo {
		margin-bottom: 40px;
	}

	.container_class .contactInfo,
	.contactForm {
		width: 100%;
	}
	.box {
		width: 100% !important;
	}

	.container_class .contactInfo .box .icon {
		min-width: 25px;
		height: 30px;
		background: #003560;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 20%;
		color: #fff;
		font-size: 15px;
	}

	.contactForm {
		width: 100%;
		padding: 20px;
		background: #fff;
		opacity: 0.8;
		box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.6);
	}
}
