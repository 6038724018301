.row {
	display: -webkit-flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
}

.mbr-main-subtitle {
	background: rgb(247, 223, 103);
	width: fit-content;
	padding: 0.4rem 0.8rem;
	position: relative;
	margin-bottom: 2rem;
}

.mbr-main-subtitle::before {
	content: '';
	position: absolute;
	left: 0px;
	bottom: -18px;
	border-left: 24px solid rgb(247, 223, 103);
	border-bottom: 18px solid transparent;
	z-index: 2;
}

.mbr-section-title {
	font-style: normal;
	line-height: 1.3;
	color: rgb(39, 39, 39);
}

.align-left {
	text-align: left;
}

.text-wrapper {
	display: flex;
}

.text-wrapper span {
	padding-right: 1rem;
}

.mbr-iconfont {
	position: relative;
	color: rgb(39, 39, 39);
}

.icon1 {
	font-size: 2.2rem;
}

.mobi-mbri-quote-left::before {
	content: '';
}

.card1-text {
	color: rgb(39, 39, 39);
}

.soc-link,
.soc-wrapper {
	color: rgb(39, 39, 39);
}

.social {
	display: inline-flex;
	align-items: center;
	margin-bottom: 0.5rem;
	padding-left: 3rem;
}

.social .mbr-iconfont {
	padding-right: 0rem;
	color: #003056;
}

.mbr-iconfont {
	position: relative;
	color: rgb(39, 39, 39);
}

.social .iconfont {
	display: block;
	font-size: 1.5rem;
	color: #003056;
}

.iconfont {
	display: block;
	font-size: 1.5rem;
	color: #003056;
}

.item {
	position: relative;
}

.card-wrap {
	height: 100%;
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	background: rgb(255, 255, 255);
	border-radius: 4px;
}

.card-wrap {
	height: 100%;
	margin-bottom: 2rem;
	display: flex;
	flex-direction: column;
	background: rgb(255, 255, 255);
	border-radius: 4px;
}
.card-wrapper,
.item-wrapper {
	overflow: hidden;
}

.image-wrap {
	clip-path: polygon(100% 0px, 100% 100%, 0% 94%, 0% 0%);
}

.card-wrap .image-wrap img {
	width: 100%;
	transition: all 0.3s ease 0s;
	height: 300px;
	object-fit: cover;
}

.card-wrap .content-wrap {
	margin: auto;
}
.content-wrap {
	height: 100%;
	justify-content: space-evenly;
	display: flex;
	flex-direction: column;
}

.mbr-role,
.social-row {
	color: rgb(53, 53, 53);
}

.card-text,
.mbr-section-btn,
.social-row {
	color: rgb(53, 53, 53);
}

.icons-menu {
	display: flex;
}

.iconfont-wrapper {
	font-size: 1rem;
	padding: 0.4rem 0px;
	margin: 0px 0.2rem;
	display: block;
	position: relative;
}

/* a {
	transition: color 0.6s ease 0s;
}
a,
a:hover {
	color: rgb(247, 223, 103);
} */

.iconfont-wrapper::before {
	position: absolute;
	content: '';
	bottom: 0px;
	left: 0px;
	transition: all 0.3s ease 0s;
	width: 100%;
	height: 0%;
	background: rgb(247, 223, 103);
	z-index: 0;
}

.mbr-iconfont {
	position: relative;
	color: rgb(39, 39, 39);
}

@media (min-width: 992px) {
	.row {
		margin-left: -16px;
		margin-right: -16px;
	}

	@media (min-width: 992px) {
		.row > [class*='col'] {
			padding-left: 16px;
			padding-right: 16px;
		}
	}
}

@media (min-width: 1400px) {
	.col-lg-3 {
		padding: 0px 1.5rem;
	}
}

@media (min-width: 768px) {
	.card-wrap .content-wrap {
		padding: 2rem;
	}

	.col-sm-6 {
		padding: 20px !important;
	}
}
