.img-fluid {
	
	height: 850px;
	margin: auto;
}

.about_title {
	font-family: 'Poppins', sans-serif;
	font-size: 40px;
	line-height: 48px;
	text-align: left;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #003560 !important;
}

.about_h6_text {
	font-family: 'Kanit', sans-serif;
	font-size: 32px;
	line-height: 51.2px;
	text-align: start;
	color: #000018;
}

.about_text {
	font-family: 'Kanit', sans-serif;
	font-size: 16px;
	line-height: 30px;
	text-align: justify;
	letter-spacing: normal;
	color: #746866;
}

@media (max-width: 991px) {
	.img-fluid {
		height: auto;
		padding: 0px;
	}

	.about_title {
		font-size: 32px;
	}

	.p-5 {
		padding: 1.5rem !important;
	}

	.about_h6_text {
		font-family: 'Kanit', sans-serif;
		font-size: 20px;
		line-height: 24px;
		text-align: start;
		color: #000018;
	}

	.about_text {
		font-family: 'Kanit', sans-serif;
		font-size: 16px;
		line-height: normal;
		text-align: justify;
		letter-spacing: normal;
		color: #746866;
	}
}
