.banner-div img {
	width: 100%;
	height: calc(100vh - 60px);
	padding: 0px;
}

.banner-overlay {
	width: 80%;
	text-align: center;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@media (max-width: 786px) {
	.banner-div img {
		height: 200px;
		padding: 0px;
	}

	.display-5 {
		font-size: calc(1rem + 1.1vw);
	}
}
