.sub_blog_container {
	margin-top: 5.5rem;
}
/* 
.default_blog {
	padding: 0px 80px;
} */

.default_blog p {
	font-size: 18px;
	text-align: justify;
	line-height: normal !important;
}

.default_Blog_header {
	color: #171a20;
	margin-bottom: 20px;
}

.default_blog h4 {
	font-weight: 500;
}

.main_blog_container li {
	text-align: justify;
}

@media (max-width: 991px) {
	.display-2 {
		font-size: calc(1.575rem + 0.9vw);
		font-weight: 300;
		line-height: 1.2;
	}

	.ms-5 {
		margin-left: 1rem !important;
	}

	.m-5 {
		margin: 1rem !important;
	}

	.display-6 {
		font-size: calc(1.375rem + -1.5vw);
		font-weight: 300;
		line-height: 1.2;
	}

	.default_blog {
		padding: 10px;
	}

	.left-image-div {
		width: 100% !important;
	}

	.left-image-div img {
		width: 100% !important;
		object-fit: cover !important;
		height: auto !important;
		margin: 0 0 1.5rem 0;
	}

	.row > * {
		padding: 0 !important;
		margin: 0;
	}

	.default_blog p {
		font-size: 16px;
		text-align: justify;
		line-height: normal !important;
	}

	.default_blog h4 {
		font-weight: 700;
		font-size: 22px;
		margin: 2rem !important;
	}

	.default_blog h2 {
		font-weight: 700;
		font-size: 22px;
		margin: 2rem !important;
	}

	.main_blog_container h2 {
		margin: 2rem !important;
	}

	.default_blog {
		padding: 0 !important;
	}

	.default_blog p {
		padding: 0 30px;
	}

	.left-image-div img {
		padding: 20px;
	}

	.right-image-div img {
		padding: 20px;
	}

	.sub_blog_container h4 {
		font-size: calc(2rem + -0.7vw);
	}

	.sub_blog_container {
		margin-top: 0.5rem;
	}

	.m-4 {
		margin: 1.5rem 0.5rem !important;
	}

	.p-5 {
		padding: 0.5rem !important;
	}
}
