.col-md-3 h5 {
	font-size: 22px;
	font-weight: 900;
}

.col-md-3 ul {
	margin: 20px 0;
}

.col-md-3 ul li {
	margin: 10px 0;
}

.footer__logo img {
	height: 100px;
}

.social_links li {
	list-style: none;
}

.social_links li a {
	margin-right: 8px;

}

.social_links li a img {
	color: #fff;
	width: 30px;
	height: 30px;
	font-size: 20px;
	border-radius: 50%;
}

.footer__footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media (max-width: 992px) {
	.footer__logo img {
		height: 80px;
	}
}
