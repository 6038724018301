.referandearn_div img {
	width: 100%;
	height: calc(100vh - 60px);
	padding: 0px;
	opacity: 0.4;
}

.referandearn {
	width: 80%;
	text-align: center;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	height: inherit;
}

.referandearn h2 {
	text-align: left;
	font-size: 48px;
}

.referandearn p {
	color: white;
	font-size: 18px;
	text-align: justify;
}

@media (max-width: 786px) {
	.referandearn_div img {
		height: calc(60vh - 60px);
		padding: 0px;
	}

	.referandearn {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.referandearn p {
		color: white;
		font-size: 14px;
		text-align: justify;
	}

	.referandearn h2 {
		text-align: left;
		font-size: 36px;
	}
}
