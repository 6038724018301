.why_sunruf_div img {
	width: 100%;
	height: calc(100vh - 60px);
	padding: 0px;
}

.why_sunruf {
	width: 80%;
	text-align: left;
	margin: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	height: inherit;
}

.why_sunruf h2 {
	text-align: left;
	font-size: 48px;
}

.why_sunruf p {
	color: white;
	font-size: 18px;
	text-align: justify;
}

.sunruf-points {
	padding-left: 18px;
}

/*
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}
@keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}

@media (max-width: 786px) {
	.why_sunruf_div img {
		width: 100%;
		height: calc(60vh - 60px);
		padding: 0px;
	}

	.why_sunruf {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.why_sunruf h2 {
		text-align: left;
		font-size: 36px;
	}

	.why_sunruf p {
		color: white;
		font-size: 14px;
		text-align: justify;
	}
}
