.card h1,
h6,
p {
	font-family: 'Poppins';
}

.main_knowledge_container {
	text-align: justify;
}

@media (max-width: 991px) {
	.main_knowledge_container h1 {
		margin: 2rem 2.5rem !important;
	}
}
