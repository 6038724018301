.custom-nav-class {
	background-color: #003056;
	width: 100%;
	z-index: 10;
}

.sticky-header {
	position: sticky;
	top: 0;
	transform: translateY(-80px);
	animation: stickyHeader 0.5s ease forwards;
}

@keyframes stickyHeader {
	0% {
		transform: translateY(-80px);
	}
	100% {
		transform: translateY(0);
	}
}

.navbar-toggler {
	outline: none;
	border: none;
}

.navbar-toggler-icon {
	color: white !important;
}

.navbar-toggler:focus {
	outline: none !important;
	border: none !important;
}

.navbar-nav {
	margin-right: 78px;
}

.navbar-nav .nav-link {
	font-weight: 500;
	font-size: 15px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	margin: 0rem 0.5rem;
}

.nav-item > a {
	position: relative;
}

.navModal_h6 {
	width: 100%;
	font-size: 12px;
	text-align: center;
	font-family: 'Poppins';
	color: white;
}

.nav-item > a::after {
	background: #fff;
	content: '';
	height: 1px;
	left: 0;
	position: absolute;
	top: 100%;
	-webkit-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: center;
	transform-origin: center;
	transition: -webkit-transform 0.6s;
	transition: transform 0.6s;
	transition: transform 0.6s, -webkit-transform 0.6s;
	width: 100%;
}

.nav-item > a:hover::after {
	-webkit-transform: scale(1);
	transform: scale(1);
}

@media screen and (max-width: 700px) {
	.custom-nav-class {
		background-color: #003056;
	}

	.leftMenu {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}

	.navbar-brand img {
		height: 55px !important;
		margin-left: 60px !important;
	}

	.row-cols-3 > * {
		width: 27%;
	}

	.modalrow {
		margin-left: 4.5rem;
	}

	.navbarModalButton {
		position: fixed;
		right: 10px;
		top: 20px;
	}

	.top-btn img {
		height: 50px !important;
		border-radius: 30%;
	}

	.top-btn {
		left: 1rem !important;
	}

	.top-btn-w {
		right: 1rem !important;
	}

	.top-btn-w img {
		height: 50px !important;
		border-radius: 30%;
	}
}

@media screen and (min-width: 1367px) {
	.custom-nav-class {
		background-color: #003056;
		width: 100%;
		z-index: 10;
	}

	.sticky-header {
		position: sticky;
		top: 0;
		transform: translateY(-80px);
		animation: stickyHeader 0.5s ease forwards;
	}

	@keyframes stickyHeader {
		0% {
			transform: translateY(-80px);
		}
		100% {
			transform: translateY(0);
		}
	}

	.navbar-toggler {
		outline: none;
		border: none;
	}

	.navbar-toggler-icon {
		color: white !important;
	}

	.navbar-toggler:focus {
		outline: none !important;
		border: none !important;
	}

	.navbar-nav {
		margin-right: 160px;
	}

	.navbar-nav .nav-link {
		font-weight: 500;
		font-size: 15px;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		margin: 0rem 0.5rem;
	}

	.nav-item > a {
		position: relative;
	}

	.navModal_h6 {
		width: 100%;
		font-size: 12px;
		text-align: center;
		font-family: 'Poppins';
		color: white;
	}

	.nav-item > a::after {
		background: #fff;
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		top: 100%;
		-webkit-transform: scaleX(0);
		transform: scaleX(0);
		-webkit-transform-origin: center;
		transform-origin: center;
		transition: -webkit-transform 0.6s;
		transition: transform 0.6s;
		transition: transform 0.6s, -webkit-transform 0.6s;
		width: 100%;
	}

	.nav-item > a:hover::after {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
