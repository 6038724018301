/* ?Solar Mart */

.solarMart-page {
	width: 80%;
	margin: auto;
	text-align: justify;
	justify-content: center;
}

.solarMart-page h2 {
	margin-bottom: 25px;
}

.solarMart-content {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	width: 100%;
}

.solarMart-content .card {
	width: 300px !important;
	margin: 5px;
}

.solarMart-content .card:hover {
	background: #e9e9e9 !important;
}

.card-img-top img {
	width: 100%;
}

/* ? End of Solar Mart */

/* site Survey */

.solar_services {
	display: flex;
	justify-content: center;
}

/* end of site Survey */

/* media Query */
.text-danger {
	color: red !important;
	font-size: 13px !important;
}

@media (max-width: 991px) {
	.solar_services {
		display: flex;
		flex-direction: column;
	}
	.display-3 {
		font-size: calc(1.525rem + -1.7vw);
		font-weight: 300;
		line-height: 1.2;
	}

	h4 {
		font-size: calc(1rem + -0.7vw);
	}

	.solarMart-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.solarMart-page {
		/* padding: 50px 20px; */
		text-align: justify;
	}

	.solarMart-page ul li {
		margin-bottom: 20px;
	}
}
