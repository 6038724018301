.profile_container {
	padding: 10px !important;
	height: 100%;
}

.profile_img {
	width: 500px;
	height: 300px !important;
	max-height: auto !important;
	padding: 20px 40px 20px 20px !important;
}

.service_refer {
	display: flex;
	justify-content: center;
}

@media (max-width: 991px) {
	.service_refer {
		display: flex;
		flex-direction: column;
	}

	.profile_img {
		padding: 20px !important;
	}

	.profile-details {
		display: flex;
		flex-direction: column !important;
	}
	.profile-details .card-body {
		margin: 10px;
	}
}
