/* CARD 1 */

.cardx {
	width: 100%;
	height: calc(100vh - 60px);
}

.card-imgx {
	width: 100%;
	height: calc(100vh - 60px);
	object-fit: cover;
	opacity: 0.9 !important;
}

.card-title {
	color: rgb(255, 255, 255);
}

.kenburns-top {
	-webkit-animation: kenburns-top 2s ease-out both;
	animation: kenburns-top 2s ease-out both;
}

@media (max-width: 990px) {
	.card-imgx {
		height: 100vh;
		aspect-ratio: 16/4;
		object-fit: cover;
	}

	.header-top p {
		font-size: 14px !important;
		text-align: center;
	}

	.header-top button {
		width: 100%;
	}

	.card-group {
		gap: 0;
	}

	.card-group > .card {
		margin-bottom: 0rem !important;
		border: none;
		border-radius: 0;
	}
}
