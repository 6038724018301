* {
	font-family: 'Poppins';
	margin: 0;
	padding: 0;
	user-select: none;
	scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400&family=Poppins:wght@200;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aref+Ruqaa+Ink&family=Cinzel:wght@600&family=Kanit:wght@400;600&family=Poppins:wght@200&display=swap');

body {
	margin: 0;
	/* background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%); */
	background-image: linear-gradient(-225deg, #fffeff 0%, #d7fffe 100%);
}

::-webkit-scrollbar {
	display: none;
}

a {
	text-decoration: none;
	color: #fff;
}

.want_to_work {
	padding: 3rem;
}

/* ~ Search BY Menu */

.search_by_menu_container {
	height: calc(100vh - 80px);
}

.searchByMenu {
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
}

.slideWrapper {
	width: 300px !important;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #003056;
	text-align: center;
	padding: 30px;
	margin: 15px auto;
	border: 1px solid #17a;
	cursor: pointer;
}

.slideWrapper:hover {
	background: #014a92;
	transition: all 0.5s ease;
	border-radius: 10px;
}

.search_by_menu_title {
	color: #fff;
}

/* ~ End of Search BY Menu */

/* TODO: approved_images */

.slideWrapper__approvedBy {
	position: relative;
	width: 250px !important;
	height: 225px;
	display: flex;
	justify-content: center;
	background: #fff;
	align-items: center;
	text-align: center;
	margin-inline: 20px;
	border: 1px solid #14a;
}

.slideWrapper__approvedBy img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	width: 100%;
	height: 200px;
	padding: 5px;
	object-fit: contain;
	aspect-ratio: 3/2;
}

/* TODO: end of approved_images */

/* ? MarQuee Style */

.marquee_container {
	margin: 50px auto;
	text-align: center;
}

.marquee_title {
	margin-top: 50px;
	color: #003065;
}

.image-wrapper {
	margin: 0px 30px;
}

.image-wrapper img {
	width: 300px;
	height: 200px;
	border: 2px solid #003065;
	padding: 20px;
	object-fit: contain;
}

.single_search_logo img {
	border: 1px solid #003065;
}

/* ? End of MarQuee Style */

/* ! RoofTop Solar Solutions */

.RWrapper {
	width: 300px !important;
	height: 200px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #fff;
	text-align: center;
	padding: 30px;
	margin: 15px auto;
	border: 1px solid #17a;
	cursor: pointer;
}

.RWrapper:hover {
	background: #e9e9e9;
	transition: all 0.5s ease-in;
	border-radius: 10px;
}

/* ! End of RoofTop Solar Solutions */

/* //* Testimonials */

.swiper {
	width: 100%;
	height: 100%;
}

.swiper_padding {
	padding-right: 3rem !important;
	padding-left: 3rem !important;
}

.swiper-slide {
	text-align: center;
	font-size: 14px;
	background: #fff;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	max-width: 10rem;
	height: 10rem;
	border-radius: 50%;
	border: 0.5rem solid #003065;
}

.swiper-client-msg {
	padding: 4rem 7rem;
	background: white;
	border-radius: 1rem;
	text-align: left;
	box-shadow: 10px 5px 12px 1px #bbb;
	position: relative;
	text-align: justify;
}

.swiper-client-msg::before {
	content: '';
	position: absolute;
	bottom: -4rem;
	left: 50%;
	transform: translateX(-50%);
	border: 2rem solid white;
	border-top-color: #003065;
}

.swiper-client-msg p::before {
	content: '\F6B0';
	position: absolute;
	top: 0;
	left: 2rem;
	font-size: 4rem;
	font-family: 'Bootstrap-Icons';
	font-weight: lighter;
	color: #003065;
}

.swiper-client-msg p::after {
	content: '\F6B0';
	position: absolute;
	rotate: 180deg;
	right: 4rem;
	font-size: 4rem;
	font-family: 'Bootstrap-Icons';
	font-weight: lighter;
	color: #003065;
}

.swiper-client-data {
	justify-content: center;
	align-items: center !important;
	margin-top: 3.2rem;
}

.roof-top-title {
	color: #003056;
	text-transform: uppercase;
}

/* //* end of Testimonials */

/* ~ Video Section */
.gallery {
	-webkit-column-count: 3;
	-moz-column-count: 3;
	column-count: 3;
	-webkit-column-width: 33%;
	-moz-column-width: 33%;
	column-width: 33%;
	padding: 0.8rem;
}

.gallery .video {
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	cursor: pointer;
	margin-bottom: 12px;
}

.gallery .video:hover {
	transform: scale(0.98);
}

.video-container {
	border-radius: 10px;
	overflow: hidden;
}

.model {
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #003056;
	overflow: hidden;
	z-index: 999;
}

.model .model-close-btn {
	position: fixed;
	top: 50px;
	right: 100px;
	padding: 0px 10px;
	background-color: #fff;
	color: #003056;
	font-size: 2rem;
	cursor: pointer;
}

.model .video-container {
	width: 100%;
	max-width: 1100px;
	height: auto;
	max-height: 100%;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	padding: 1.5rem 0 1.5rem;
	margin: 2rem auto;
}

/* ~ Video Section */

.useful_links li a {
	color: #003056;
}

@media (max-width: 991px) {
	.gallery {
		-webkit-column-count: 2;
		-moz-column-count: 2;
		column-count: 2;
	}

	.solar_power_system {
		width: 9rem;
		margin: auto;
	}

	.rooftop_solutions {
		width: 9rem;
		margin: auto;
	}

	.rooftop_title {
		font-size: 11px;
	}
	.w-50 {
		width: 80% !important;
	}

	.search_by_menu_container {
		height: auto;
	}

	.ssgNext {
		display: none !important;
	}

	.sspBefore {
		display: none !important;
	}

	.apNext {
		display: none !important;
	}

	.apBefore {
		display: none !important;
	}

	.home-banner img {
		height: auto !important;
	}
}

@media (max-width: 480px) {
	.gallery {
		-webkit-column-count: 1;
		-moz-column-count: 1;
		column-count: 1;
		-webkit-column-width: 100%;
		-moz-column-width: 100%;
		column-width: 100%;
	}
}
/* end of Video Section */

@media (max-width: 992px) {
	.image-wrapper img {
		width: 200px;
		height: 150px;
	}

	.image-wrapper {
		margin: 0 15px;
	}

	.swiper-client-msg {
		padding: 2rem 4rem;
		background: white;
		border-radius: 1rem;
		text-align: left;
		box-shadow: 10px 5px 12px 1px #bbb;
		position: relative;
	}
	.swiper-client-msg p::before {
		content: '\F6B0';
		position: absolute;
		top: 0;
		left: 2rem;
		font-size: 2rem;
		font-family: 'Bootstrap-Icons';
		font-weight: lighter;
		color: #003065;
	}

	.swiper-client-msg p::after {
		content: '\F6B0';
		position: absolute;
		rotate: 180deg;
		right: 4rem;
		font-size: 2rem;
		font-family: 'Bootstrap-Icons';
		font-weight: lighter;
		color: #003065;
	}

	.swiper_padding {
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}

	.want_to_work {
		padding: 1.5rem;
	}

	.searchByMenu {
		display: grid;
		grid-template-columns: 100%;
	}

	.slideWrapper__approvedBy {
		position: relative;
		width: 300px !important;
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		text-align: center;
		margin: 10px;
	}

	.slideWrapper__approvedBy img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		width: 100%;
		height: 150px;
		object-fit: contain;
		aspect-ratio: 3/2;
	}
	.container-md {
		padding: 2rem;
	}

	.searchByMenu {
		display: grid;
		grid-template-columns: 50% 50%;
	}

	.RWrapper {
		width: 150px !important;
		height: 180px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #fff;
		text-align: center;
		padding: 10px;
		margin: 15px auto;
		border: 1px solid #17a;
		cursor: pointer;
	}

	.slideWrapper {
		width: 150px !important;
		height: 180px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: #003056;
		text-align: center;
		padding: 10px;
		margin: 15px auto;
		border: 1px solid #17a;
		cursor: pointer;
	}

	.search_by_menu_title {
		margin: 0 !important;
	}

	.roof-top-title {
		color: #003056;
		text-transform: uppercase;
		margin: 0 !important;
	}
}
